import React from "react"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>Nicht gefunden</h1>
    <p>Dieser Ort ist auf jeden Fall nicht gut angebunden. ;)</p>
  </>
)

export default NotFoundPage
